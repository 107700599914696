<template>
  <div class="col-12">
    <div class="card-body card">
        <h5>
            المهام <button class="btn btn-sm btn-success" v-b-modal.create @click="create = {}" v-if="canAdd">
                <i class="fa fa-plus"></i>
                إضافة
            </button>
        </h5>
        <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        المسمى
                    </th>
                    <th>
                        بيان المهمة
                    </th>
                    <th>
                        العدد المطلوب
                    </th>
                    <th>
                        الراتب المقدر
                    </th>
                    <th>
                        فترة العمل
                    </th>
                    <th>
                        خيارات
                    </th>
                </thead>
                <tbody>
                    <tr v-for="item in list" :key="item._id">
                        <td>
                            {{ item.title }}
                        </td>
                        <td>
                            {{ item.details }}
                        </td>
                        <td>
                            {{ item.max }}
                        </td>
                        <td>
                            {{ item.salary }}
                        </td>
                        <td>
                            {{ item.time }}
                        </td>
                        <td>
                            <button class="btn btn-sm btn-success" v-b-modal.create @click="create = item">
                                <i class="fa fa-edit"></i>
                            </button>
                            <button class="btn btn-sm btn-danger" @click="deleteItem(item._id)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <b-modal id="create" title="اضافة" hide-footer>
        <div class="form-group">
          <h5 for="">المسمى</h5>
          <input type="text"
            class="form-control" placeholder="اكتب هنا..." v-model="create.title">
        </div>
        <div class="form-group">
          <h5 for="">بيان المهمة</h5>
          <textarea class="form-control" placeholder="اكتب هنا..." v-model="create.details" rows="3"></textarea>
        </div>
        <div class="form-group">
          <h5 for="">العدد المطلوب</h5>
          <input type="number"
            class="form-control" placeholder="اكتب هنا..." v-model="create.max">
        </div>
        <div class="form-group">
          <h5 for="">الراتب المقدر</h5>
          <input type="number"
            class="form-control" placeholder="اكتب هنا..." v-model="create.salary">
        </div>
        <div class="form-group">
          <h5 for="">فترة العمل</h5>
          <input type="text"
            class="form-control" placeholder="اكتب هنا..." v-model="create.time">
        </div>
        <div class="col-12 text-center">
            <button class="btn btn-primary" @click="addNow()" v-if="!create._id">
                اضافة المهمة
            </button>
            <button class="btn btn-primary" @click="updateNow()" v-if="create._id">
                تعديل المهمة
            </button>
        </div>
    </b-modal>
  </div>
</template>

<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            current: {},
            create: {},
            admins: [],
            user: JSON.parse(localStorage.getItem("user")),
            canAdd: checkPer('tasks2'),
            list: []
        }
    },
    created(){
        var g = this;
        $.post(api + '/admin/admins/names', {
            jwt: g.user.jwt
        }).then(function(r){
            g.admins = r.response;
        })
        g.gett()
    },
    methods: {
        deleteItem(id){
            var g = this;
            if(confirm("متأكد من الحذف؟")){
                $.post(api + '/admin/web/tasks2/delete', {
                    jwt: g.user.jwt,
                    id: id
                }).then(function(r){
                    g.gett()
                    alert("تم الحذف بنجاح", 100)
                })
            }
        },
        gett(){
            var g = this;
            $.post(api + '/admin/web/tasks2/list', {
                jwt: g.user.jwt
            }).then(function(r){
                g.list = r.response
            })
        },
        addNow(){
            var g = this;
            $.post(api + '/admin/web/tasks2/create', {
                jwt: g.user.jwt,
                title: g.create.title,
                details: g.create.details,
                max: g.create.max,
                salary: g.create.salary,
                time: g.create.time
            }).then(function(r){
                g.gett()
                alert("تم الاضافة بنجاح", 100)
                $("#create___BV_modal_header_ > button").click()
            })
        },
        updateNow(){
            var g = this;
            $.post(api + '/admin/web/tasks2/edit', {
                jwt: g.user.jwt,
                id: g.create._id,
                title: g.create.title,
                details: g.create.details,
                max: g.create.max,
                salary: g.create.salary,
                time: g.create.time
            }).then(function(r){
                g.gett()
                alert("تم التعديل بنجاح", 100)
                $("#create___BV_modal_header_ > button").click()
            })
        },
    }
}
</script>

<style>

</style>